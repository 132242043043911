import React from "react"
import { useLocation } from "@reach/router"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Logo from "../Logo"
import DesktopMenu from "./DesktopMenu"
import MobileMenu from "./MobileMenu"
import { analyticsAction } from "/src/utils/analytics"

function Header() {
  const { pathname } = useLocation()
  const url = pathname === "/service/" ? pathname : "/"

  let blogSlug = null
  let pageSlug = null

  if (pathname.startsWith("/blog")) {
    const blogMatch = pathname.match(/\/blog\/([^/?]+)/)
    blogSlug = blogMatch ? blogMatch[1] : "main" // Если нет slug, устанавливаем "main"
  } else {
    const pageMatch = pathname.match(/\/([^/?]+)/)
    if (pageMatch) {
      pageSlug = pageMatch[1]
    }
  }

  const utmParams = blogSlug
    ? `?utm_source=blog&utm_medium=cta&utm_campaign=${blogSlug}&utm_content=header_menu`
    : pageSlug
      ? `?utm_source=landing&utm_medium=cta&utm_campaign=${pageSlug}&utm_content=header_menu`
      : "?utm_source=landing&utm_medium=cta&utm_campaign=main&utm_content=header_menu"

  const signInUrl = `https://app.filmustage.com/sign/in${utmParams}`
  const signUpUrl = `https://app.filmustage.com/sign/up${utmParams}`

  return (
    <header className="fixed w-full z-30 bg-fGray-900">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-12 sm:h-14">
          {/* Site branding */}
          <div className="flex-shrink-0">
            {/* Logo */}
            <AnchorLink
              stripHash={true}
              to={`${url}#home`}
              className="block"
              aria-label="Filmustage"
              onClick={() => analyticsAction("landing_home_click")}
            >
              <Logo />
            </AnchorLink>
          </div>

          {/* Desktop navigation */}
          <DesktopMenu {...{ signInUrl, signUpUrl }} />
          {/* Mobile menu */}
          <MobileMenu {...{ signInUrl, signUpUrl }} />
        </div>
      </div>
    </header>
  )
}

export default Header
