const config = require(`./config`)

export const getSocialLinks = (network, author) => {
  switch (network) {
    case "twitter":
      return `https://twitter.com/${author.twitter.replace(/^@/, ``)}/`
    case "facebook":
      return `https://www.facebook.com/${author.facebook.replace(/^\//, ``)}/`
    default:
      return null
  }
}

export const targetBlank = () => {
  const siteUrl = config.siteUrl
  const getLinks = document.querySelectorAll(".post a, .filmLegalPage a")

  for (let i = 0; i < getLinks.length; i++) {
    const link = getLinks[i]

    if (
      link.href &&
      link.href.indexOf(siteUrl) === -1 &&
      link.href.indexOf("filmustage.com") === -1 && // Ensure the link does not contain "filmustage.com"
      link.href.indexOf("#") === -1 // Ensure the link does not contain "#"
    ) {
      link.target = "_blank"
      link.rel = "noreferrer"
    }

    if (link.getAttribute("href") === "#") {
      link.addEventListener("click", event => {
        event.preventDefault()
      })
    }
  }
}
