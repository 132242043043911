/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
const { targetBlank } = require("./src/utils/urls")
const { analyticsAction } = require("./src/utils/analytics")

const {
  gallery,
  video,
  audio,
  toggle,
  beforeAfter,
} = require("./src/utils/ghost")

exports.onClientEntry = () => {
  const hostname = window.location.hostname
  // If not on localhost or 127.0.0.1, override console methods
  if (hostname !== "localhost" && hostname !== "127.0.0.1") {
    console.log = () => {}
    console.debug = () => {}
    console.info = () => {}
    console.warn = () => {}
  }
}

const LoadAn = async time => {
  if (typeof window.analytics?.load !== "undefined") {
    window.analytics.load(process.env.GATSBY_SEGMENT_PROD_KEY)

    let callbackExecuted = false
    const readyPromise = new Promise(resolve => {
      window.analytics?.ready(() => {
        callbackExecuted = true
        resolve()
      })
    })

    await Promise.race([
      readyPromise,
      new Promise(resolve => {
        setTimeout(() => {
          if (!callbackExecuted) {
            resolve()
          }
        }, time + 1000)
      }),
    ])
  } else {
    await new Promise(resolve => {
      setTimeout(() => resolve(), time)
    })
  }
}

setTimeout(() => {
  analyticsAction("landing_visit")
}, 100)

const isDevEnvironment =
  window.location.hostname.startsWith("landev.filmustage.com") ||
  window.location.hostname.startsWith("localhost")

exports.onRouteUpdate = () => {
  const externalLinks = document.querySelectorAll("a[href^='https']")

  externalLinks.forEach(link => {
    link.addEventListener("click", async e => {
      link.disabled = true
      link.style.pointerEvents = "none"
      link.classList.add("animate-pulse")

      function clearAnimation(time) {
        setTimeout(() => {
          link.disabled = false
          link.style.pointerEvents = "auto"
          link.classList.remove("animate-pulse")
        }, time)
      }

      if (isDevEnvironment && link.href.includes("app.filmustage.com")) {
        link.href = link.href.replace(
          "app.filmustage.com",
          "dev.filmustage.com",
        )
      }

      if (link.getAttribute("target") === "_blank") {
        await LoadAn(0)
        clearAnimation(0)
      } else {
        e.preventDefault()
        await LoadAn(500)
        window.location.href = link.getAttribute("href")
        clearAnimation(1500)
      }
    })
  })

  setTimeout(() => {
    analyticsAction("user_load_page")
  }, 100)

  // Ghost card scripts
  // https://ghost.org/docs/themes/content/
  gallery()
  video()
  audio()
  toggle()
  beforeAfter()
  targetBlank()
}

exports.onInitialClientRender = () => {
  const scriptSrc = isDevEnvironment
    ? "https://assets.sandbox.cello.so/attribution/latest/cello-attribution.js"
    : "https://assets.cello.so/attribution/latest/cello-attribution.js"

  const script = document.createElement("script")
  script.type = "module"
  script.src = scriptSrc
  script.async = true
  document.head.appendChild(script)
}

/*
  https://www.gatsbyjs.com/docs/how-to/performance/add-offline-support-with-a-service-worker/
  https://github.com/gatsbyjs/gatsby/issues/9087#issuecomment-774680408

  exports.onServiceWorkerUpdateReady = () => window.location.reload()
}*/

//import { focusHandling } from "cruip-js-toolkit"
// On change location:
// https://stackoverflow.com/questions/61274365/allow-component-to-detect-route-change-in-gatsby
// https://github.com/gatsbyjs/gatsby/issues/23051#issuecomment-613557479
