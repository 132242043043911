export const analyticsAction = async (action, add) => {
  const cookieName = "The_Boy_Who_Lived"

  const getMyCookieUtm = () =>
    new Promise(resolve => {
      const checkFn = () => typeof window.getMyCookie === "function"
      if (checkFn()) return resolve(window.getMyCookie(cookieName))
      const interval = setInterval(() => {
        if (checkFn()) {
          clearInterval(interval)
          resolve(window.getMyCookie(cookieName))
        }
      }, 100)
    })

  const utmCookieValue = await getMyCookieUtm()
  const getBaseUrlWithUtm = () =>
    window.location.href.split("?")[0] + utmCookieValue
  const pageData = utmCookieValue
    ? { search: utmCookieValue, url: getBaseUrlWithUtm() }
    : {}

  const utmParams = (utmCookieValue || "")
    .replace(/^\?/, "")
    .split("&")
    .reduce((acc, param) => {
      const [key, value] = param.split("=")
      if (key && value) acc[decodeURIComponent(key)] = decodeURIComponent(value)
      return acc
    }, {})

  const campaignParams = {
    source: utmParams.utm_source || "",
    medium: utmParams.utm_medium || "",
    name: utmParams.utm_campaign || "",
    content: utmParams.utm_content || "",
    term: utmParams.utm_term || "",
    ...Object.fromEntries(
      Object.entries(utmParams).filter(([key]) => !key.startsWith("utm_")),
    ),
  }

  if (action === "user_load_page") {
    window.analytics?.page({
      context: { campaign: campaignParams },
      ...pageData,
      ...utmParams,
    })
    return
  }

  setTimeout(async () => {
    window.analytics?.track(
      action,
      { ...add },
      { page: pageData, context: { campaign: campaignParams }, ...utmParams },
    )

    if (action === "landing_sign_up_click") {
      await window.gtag("config", process.env.GATSBY_GADS_COVERSION_ID)
      window.gtag("event", "conversion", {
        send_to: process.env.GATSBY_GADS_COVERSION_SIGN_UP,
        ...pageData,
        ...utmParams,
      })
    }
  }, 200)
}
