import React, { useEffect, useRef, useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import {
  activeClassName,
  convertHeaderMenuItem,
  removeFeaturesHeading,
} from "./HeaderNavItem"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { analyticsAction } from "/src/utils/analytics"

function MobileDropdown({
  item,
  child,
  slug,
  showAllLink = false,
  mobileNavOpen,
}) {
  const ghostSettings = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
          slugs {
            blog
            tag
          }
        }
      }
    }
  `)
  const { blog: blogSlug, tag: tagSlug } = ghostSettings.site.siteMetadata.slugs
  const typeBlog = item.url === `${blogSlug}/`
  const [mobileDropNav, setMobileDropNav] = useState(false)
  const mobileDropNavRef = useRef(null)
  const childClass = `text-gray-400 text-sm flex font-medium hover:text-gray-200 py-2`

  useEffect(() => {
    mobileNavOpen === false && setMobileDropNav(false)
  }, [mobileNavOpen])

  return (
    <li
      onClick={() => {
        //e.preventDefault()
        setMobileDropNav(!mobileDropNav)
      }}
      ref={mobileDropNavRef}
      className={`prevent-close-nav ${
        mobileDropNav && typeBlog && "border-b border-gray-700"
      } overflow-hidden`}
    >
      <span className="prevent-close-nav flex text-gray-300 cursor-pointer py-2">
        {item.label}
        <svg
          className="prevent-close-nav w-3 h-3 fill-current text-gray-500 cursor-pointer ml-1.5 mt-1.5 flex-shrink-0 transition-all duration-300 ease-in-out"
          viewBox="0 0 12 12"
          xmlns="http://www.w3.org/2000/svg"
          style={!mobileDropNav ? { transform: "rotate(90deg)" } : {}}
        >
          <path
            className="prevent-close-nav"
            d="M10.28 4.305L5.989 8.598 1.695 4.305A1 1 0 00.28 5.72l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z"
          />
        </svg>
      </span>
      <ul
        className="pl-4 transition-all duration-300 ease-in-out"
        style={
          mobileDropNav && mobileNavOpen
            ? {
                maxHeight: mobileDropNavRef.current.scrollHeight,
                opacity: 1,
              }
            : { maxHeight: 0, opacity: 0.7 }
        }
      >
        {showAllLink && (
          <li>
            <Link
              to={`${slug}/`}
              className={childClass}
              activeClassName={activeClassName}
            >
              All
            </Link>
          </li>
        )}

        {child.map((item, i) => {
          if (item.url?.charAt(0) === "/" || typeBlog)
            return (
              <li key={i}>
                <Link
                  to={
                    typeBlog
                      ? `${blogSlug}${tagSlug}/${item.slug}/`
                      : `${item.url}`
                  }
                  className={childClass}
                  activeClassName={activeClassName}
                >
                  {typeBlog ? item.name : removeFeaturesHeading(item.label)}
                </Link>
              </li>
            )

          if (item.url.charAt(0) === "#")
            return (
              <li key={i}>
                <AnchorLink
                  key={item.url}
                  stripHash={true}
                  to={`/${item.url}`}
                  className={childClass}
                  onAnchorLinkClick={() =>
                    analyticsAction(
                      `landing_${convertHeaderMenuItem(item.label)}_click`,
                    )
                  }
                >
                  {removeFeaturesHeading(item.label)}
                </AnchorLink>
              </li>
            )

          return (
            <li key={i}>
              <a
                href={item.url}
                className={childClass}
                target="_blank"
                rel="noreferrer"
                onClick={() =>
                  analyticsAction(
                    `landing_${convertHeaderMenuItem(item.label)}_click`,
                  )
                }
              >
                {removeFeaturesHeading(item.label)}
              </a>
            </li>
          )
        })}
      </ul>
    </li>
  )
}

export default MobileDropdown
