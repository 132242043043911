import React from "react"
import HeaderNavItem from "./HeaderNavItem"
import { analyticsAction } from "/src/utils/analytics"

function DesktopMenu({ signInUrl, signUpUrl }) {
  return (
    <nav className="hidden fMd:flex fMd:flex-grow">
      {/* Desktop menu links */}
      <ul className="flex flex-grow justify-end items-center">
        <HeaderNavItem />
      </ul>

      {/* Desktop sign in links */}
      <ul className="flex flex-grow justify-end items-center">
        <li>
          <a
            href={signInUrl}
            className="font-medium text-gray-300 hover:text-gray-200 px-4 py-3 flex items-center capitalize transition duration-150 ease-in-out"
            onClick={async () => await analyticsAction("landing_sign_in_click")}
          >
            Sign In
          </a>
        </li>

        <li>
          <a
            href={signUpUrl}
            className="btn-sm text-gray-800 bg-fOrange-900 hover:bg-fOrange-800 ml-3"
            onClick={async () => await analyticsAction("landing_sign_up_click")}
          >
            Try for Free
          </a>
        </li>
      </ul>
    </nav>
  )
}

export default DesktopMenu
